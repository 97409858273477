<script>
  import { receiptsRef } from '../../utils/services';
  import { clickOutside } from '../../utils/clickOutside';
  import {
    user,
    vendors,
    customers,
    selectedOrgId,
    transactions,
    bankAccounts,
  } from '../../utils/store';
  import { createEventDispatcher, onMount } from 'svelte';
  import { getNotificationsContext } from 'svelte-notifications';
  import { Shadow } from 'svelte-loading-spinners';
  import Input from '../Input.svelte';
  import InputUsd from '../InputUSD.svelte';
  import InputPercentage from '../InputPercentage.svelte';
  import DatePicker from '../DatePicker.svelte';
  import Select from '../Select.svelte';
  import FileDropzone from '../FileDropzone.svelte';
  import {
    createTransaction,
    updateTransaction,
  } from '../../utils/transactions';
  import { urlToFile, uploadFiles } from '../../utils/files';
  import BaseModal from './BaseModal.svelte';

  const { addNotification } = getNotificationsContext();

  const dispatch = createEventDispatcher();

  export let show = false;

  let loadingReceipts = false;
  let prevFiles = [];
  let files = {
    accepted: [],
    rejected: [],
  };

  let end = new Date();
  const getOptionLabel = (option) => option.name;
  const getSelectionLabel = (option) => option.name;
  const optionIdentifier = 'id';

  let transactionTypes = ['Income', 'Expense'];
  let methods = ['Cash', 'Check', 'ETF'];
  let incomeCategories = ['Other Income', 'Sales', 'Services'];
  let expenseCategories = [
    'Advertising/Marketing',
    'Insurance',
    'Meals',
    'Office Supplies',
    'Other Expense',
    'Professional Services',
    'Software',
    'Travel',
    'Utilities',
  ];
  let selectedIncomeCategory = {
    value: incomeCategories[0],
    label: incomeCategories[0],
  };
  let selectedExpenseCategory = {
    value: expenseCategories[0],
    label: expenseCategories[0],
  };
  let selectedVendor = {};
  let selectedCustomer = {};

  let salesTax = 0;
  $: salesTax = transactionType.value == 'Expense' ? 0 : salesTax;

  $: preTaxTotal = (0).toFixed(2);
  $: totalTax = Number(preTaxTotal) * (Number(salesTax) / 100);
  $: totalAmount = (
    Math.round((Number(preTaxTotal) + Number(totalTax)) * 100) / 100
  ).toFixed(2);

  if ($customers.length > 0) {
    selectedCustomer = $customers[0];
  }
  if ($vendors.length > 0) {
    selectedVendor = $vendors[0];
  }

  export let edit = false;
  export let editReceipts = edit;
  export let id = ''; // this is the transaction firestore object id
  export let transactionId = 0;
  export let transactionType = { value: 'Income', label: 'Income' };
  export let bankAccount;
  export let selectedSource = {};
  export let description = '';
  export let amountTotal = (0).toFixed(2);
  export let selectedDate = new Date(); // date user chose, defaults to today
  export let selectedMethod = { value: methods[0], label: methods[0] };
  export let selectedCategory = {
    value: incomeCategories[0],
    label: incomeCategories[0],
  };

  function listAllImages() {
    loadingReceipts = true;
    // console.log('listing images');
    let numImages = 0;
    let imagesRef = receiptsRef.child(`${$selectedOrgId}/${transactionId}`);
    imagesRef
      .listAll()
      .then((res) => {
        if (res.items.length === 0) {
          loadingReceipts = false;
          editReceipts = false;
        }
        res.items.forEach((itemRef, index) => {
          //   console.log(`Retrieving image (${index}) ${itemRef.name}`);
          itemRef
            .getDownloadURL()
            .then((url) => {
              urlToFile(url, itemRef.name).then((file) => {
                files.accepted.push(file);
                prevFiles.push(file);
                numImages = numImages + 1;
                if (numImages === res.items.length) {
                  //   console.log('finished loading');
                  loadingReceipts = false;
                  editReceipts = false;
                }
              });
            })
            .catch(function (error) {
              console.log('error downloading file: ', error);
            });
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log('error listing images', error);
      });
  }

  onMount(() => {
    if (Object.keys(selectedSource).length != 0) {
      if (transactionType.value == 'Income') {
        selectedIncomeCategory = selectedCategory;
        selectedCustomer = {
          account: {
            accountNumber: selectedSource.accountNumber,
          },
          name: selectedSource.name,
          id: selectedSource.id,
          type: selectedSource.type,
        };
      } else if (transactionType.value == 'Expense') {
        selectedExpenseCategory = selectedCategory;
        selectedVendor = {
          account: {
            accountNumber: selectedSource.accountNumber,
          },
          name: selectedSource.name,
          id: selectedSource.id,
          type: selectedSource.type,
        };
      }
    }
    totalAmount = amountTotal;
  });

  function getTransactionNumber() {
    let numbers = [];
    numbers = $transactions.sort(function (a, b) {
      return a.transactionNumber < b.transactionNumber ? 1 : -1;
    });

    if (numbers.length == 0) {
      return 1;
    }

    return numbers[0].transactionNumber + 1;
  }

  function add() {
    let category = '';
    let type;
    if (transactionType.value == 'Income') {
      category = selectedIncomeCategory;
      type = 'customer';
      selectedSource = selectedCustomer;
    }
    if (transactionType.value == 'Expense') {
      category = selectedExpenseCategory;
      type = 'vendor';
      selectedSource = selectedVendor;
    }

    let transaction = {
      date: selectedDate,
      description: description,
      method: selectedMethod.value,
      category: category.value,
      totalAmount: parseFloat(totalAmount),
      type: transactionType.value,
      transactionNumber: getTransactionNumber(),
      invoiceId: null,
      bankAccount: {
        id: bankAccount.id,
      },
      source: {
        id: selectedSource.id,
        type: type,
      },
    };

    createTransaction(transaction)
      .then((transactionID) => {
        console.log(transactionID);
        uploadFiles(transactionId, prevFiles, files);
        addNotification({
          text: 'Successfully created transaction!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        show = false;
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to add transaction.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  function update() {
    let category = '';
    let type;
    if (transactionType.value == 'Income') {
      category = selectedIncomeCategory;
      type = 'customer';
      selectedSource = selectedCustomer;
    }
    if (transactionType.value == 'Expense') {
      category = selectedExpenseCategory;
      type = 'vendor';
      selectedSource = selectedVendor;
    }

    let transaction = {
      id: id,
      date: selectedDate,
      description: description,
      method: selectedMethod.value,
      category: category.value,
      totalAmount: parseFloat(totalAmount),
      type: transactionType.value,
      transactionNumber: getTransactionNumber(),
      invoiceId: null,
      bankAccount: {
        id: bankAccount.id,
      },
      source: {
        id: selectedSource.id,
        type: type,
      },
    };

    updateTransaction(transaction)
      .then((transactionID) => {
        console.log(transactionID);
        uploadFiles(transactionId, prevFiles, files);
        addNotification({
          text: 'Successfully updated transaction!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        show = false;
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to update transaction.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  let no = false;
  function addCustomer() {
    dispatch('notify', { newCustomer: true });
  }
  function addVendor() {
    dispatch('notify', { newVendor: true });
  }
</script>

<BaseModal bind:show>
  <!-- Heading -->
  <div class="w-full flex justify-between">
    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white">
      {#if !edit}
        New Transaction
      {:else}
        Edit Transaction
      {/if}
    </h3>
    <span
      on:click={() => (show = false)}
      class="hidden md:flex text-cfa-secondary-gray hover:bg-socius-primary hover:text-white rounded-full w-8 h-8 flex justify-center items-center"
    >
      ×
    </span>
  </div>

  <!-- Body of the modal -->
  <div class="mt-2">
    <!-- Row 1 -->
    <div class="w-full block md:flex">
      <div class="w-full md:w-1/2 mr-1 md:mr-4">
        <Select
          label="Transaction Type"
          items={transactionTypes}
          placeholder="Select transaction type"
          bind:selectedValue={transactionType}
        />
      </div>
      <div class="w-full md:w-1/2 md:ml-4 mt-4 md:mt-0">
        <Select
          label="Bank Account"
          items={$bankAccounts}
          {getOptionLabel}
          {getSelectionLabel}
          {optionIdentifier}
          placeholder="Select bank account for transaction"
          bind:selectedValue={bankAccount}
        />
      </div>
    </div>

    <hr class="my-4 dark:hidden" />

    <div class="grid grid-col-12 gap-y-4 md:gap-y-0 mt-4">
      {#if $customers.length == 0 && !no && transactionType.value == 'Income'}
        <div
          class="row-span-1 flex items-center justify-between w-full text-xs border border-red-500 p-2 mb-2"
        >
          <span class="text-red-500"
            >There are no customers. Would you like to add a new one?</span
          >
          <div class="text-sm">
            <i
              class="fas fa-plus-circle text-green-500 px-2"
              on:click={addCustomer}
            />
            <i
              class="fas fa-trash-alt text-red-500"
              on:click={() => (no = true)}
            />
          </div>
        </div>
      {/if}
      {#if $vendors.length == 0 && !no && transactionType.value == 'Expense'}
        <div
          class="row-span-1 flex items-center justify-between w-full text-xs border border-red-500 p-2 mb-2"
        >
          <span class="text-red-500"
            >There are no vendors. Would you like to add a new one?</span
          >
          <div class="text-sm">
            <i
              class="fas fa-plus-circle text-green-500 px-2"
              on:click={addVendor}
            />
            <i
              class="fas fa-trash-alt text-red-500"
              on:click={() => (no = true)}
            />
          </div>
        </div>
      {/if}

      <!-- Row 1 -->
      <div class="row-span-1 md:flex md:justify-between w-full">
        <div class="w-full md:w-1/2 mr-1 md:mr-4">
          {#if transactionType.value == 'Income'}
            <Select
              label="Customer"
              items={$customers}
              {getOptionLabel}
              {getSelectionLabel}
              {optionIdentifier}
              placeholder="Select customer"
              bind:selectedValue={selectedCustomer}
            />
          {:else}
            <Select
              label="Vendor"
              items={$vendors}
              {getOptionLabel}
              {getSelectionLabel}
              {optionIdentifier}
              placeholder="Select vendor"
              bind:selectedValue={selectedVendor}
            />
          {/if}
        </div>
        <div class="w-full md:w-1/2 md:ml-4 mt-4 md:mt-0">
          <Input
            label="Description"
            bind:value={description}
            placeholder="Short description"
          />
        </div>
      </div>

      <!-- Row 2 -->
      <div class="row-span-1 md:flex md:justify-between w-full mt-4 text-xs">
        <div class="w-full md:w-1/2 mr-1 md:mr-4">
          <InputUsd
            label="Pre-tax Total"
            bind:value={preTaxTotal}
            placeholder="0.00"
          />
        </div>
        <div class="w-full md:w-1/2 md:ml-4 text-xs mt-4 md:mt-0">
          <label
            for="sales-tax"
            class="text-small text-gray-600 dark:text-gray-400"
          >
            Sales Tax Rate
            <span class="ml-1 small-text relative bottom-1 hidden md:inline"
              >*only if applicable</span
            >
          </label>
          <InputPercentage
            bind:value={salesTax}
            placeholder="6"
            disabled={transactionType.value == 'Expense'}
          />
        </div>
      </div>

      <!-- Row 3 -->
      <div class="row-span-1 md:flex md:justify-between w-full mt-4 text-xs">
        <div class="w-full md:w-1/2 mr-1 md:mr-4">
          <InputUsd
            label="Total Amount"
            bind:value={totalAmount}
            placeholder="0.00"
            disabled={!edit}
          />
        </div>
        <div class="w-full md:w-1/2 ml-4 relative mt-4 md:mt-0">
          <DatePicker bind:selectedDate {end} />
        </div>
      </div>

      <!-- Row 4 -->
      <div class="row-span-1 md:flex md:justify-between w-full mt-4">
        <div class="w-full md:w-1/2 mr-1 md:mr-4">
          <Select
            label="Method"
            items={methods}
            placeholder="Select method type"
            listPlacement="top"
            bind:selectedValue={selectedMethod}
          />
        </div>
        <div class="w-full md:w-1/2 md:ml-4 mt-4 md:mt-0">
          {#if transactionType.value == 'Income'}
            <Select
              label="Category"
              items={incomeCategories}
              placeholder="Select category"
              listPlacement="top"
              bind:selectedValue={selectedIncomeCategory}
            />
          {:else}
            <Select
              label="Category"
              items={expenseCategories}
              placeholder="Select category"
              listPlacement="top"
              bind:selectedValue={selectedExpenseCategory}
            />
          {/if}
        </div>
      </div>

      {#if transactionType.value == 'Expense' && !editReceipts}
        <div class="row-span-1 w-full mt-4">
          <FileDropzone bind:files />
        </div>
      {:else if transactionType.value == 'Expense' && editReceipts}
        <div class="row-span-1 w-full mt-4">
          <span
            class="p-5 flex items-center justify-center text-xs border-2 border-dashed border-gray-800 dark:border-gray-300 cursor-pointer dark:bg-gray-800 dark:text-gray-300"
            on:click={listAllImages}>View/Edit Files</span
          >
        </div>
        {#if loadingReceipts}
          <div class="my-4 w-full h-full flex justify-center items-center">
            <Shadow size="25" unit="px" duration="1s" color="#38d3ff" />
          </div>
        {/if}
      {/if}
    </div>
  </div>

  <!-- Footer -->
  <div class="mt-6 w-full flex items-center justify-center cursor-pointer">
    <div class="w-1/2 flex justify-end mr-4">
      {#if !edit}
        <span
          on:click={add}
          class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
        >
          Add Transaction
        </span>
      {:else}
        <span
          on:click={update}
          class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
        >
          Update Transaction
        </span>
      {/if}
    </div>
    <div class="w-1/2 flex justify-start ml-4">
      <span
        on:click={() => (show = false)}
        class="flex items-center justify-center text-xs text-red-600 hover:text-gray-900 h-8 w-16 rounded-full hover:bg-red-100"
      >
        Close
      </span>
    </div>
  </div>
</BaseModal>

<style>
  .small-text {
    font-size: 0.5rem;
  }
  .text-small {
    font-size: 0.65rem;
  }
</style>
