import { get } from 'svelte/store';

export async function createVendor(vendors) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/vendors/${selectedOrgId}/create`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(vendors),
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully created vendors ', data);
        return data["vendorsID"];
      } else {
        console.log('failed to create vendors');
        throw new Error("failed to create vendors")
      }
}

export async function updateVendor(vendors) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/vendors/${selectedOrgId}/update/${vendors.id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(vendors),
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully updated vendors ', data);
        return data["vendorsID"];
      } else {
        console.log('failed to updated vendors');
        throw new Error("failed to updated vendors")
      }
}

export async function deleteVendor(vendorsID) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/vendors/${selectedOrgId}/delete/${vendorsID}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully deleted vendors ', data);
        return data["vendorsID"];
      } else {
        console.log('failed to delete vendors');
        throw new Error("failed to delete vendors")
      }
}
