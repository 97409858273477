<script>
  import dayjs from 'dayjs';
  import { Datepicker, InlineCalendar } from 'svelte-calendar';
  import { darkMode } from '../utils/store';

  const sevenYears = 1000 * 60 * 60 * 24 * 365 * 7;
  let today = new Date(); // this is today

  export let label = 'Date';
  export let start = new Date(Date.now() - sevenYears);
  export let end = new Date(Date.now() + sevenYears); // default end is 1 year in the future
  export let selectedDate = today; // date user chose, defaults to today
  export let formattedDate = '';

  const theme = {
    calendar: {
      width: '500px',
      colors: {
        text: {
          primary: '#eee',
          highlight: '#fff',
        },
        background: {
          primary: '#6B7280',
          highlight: '#38d3ff',
          hover: '#222',
        },
        border: '#222',
      },
    },
  };
</script>

<span class="text-small block text-gray-700 dark:text-gray-400">{label}</span>

<!-- <div
  class="flex w-full absolute inset-x-0 bottom-0 border-transparent border-b border-gray-600 dark:text-gray-300"
> -->
{#if $darkMode}
  <Datepicker
    {theme}
    {start}
    {end}
    bind:selected={selectedDate}
    bind:formatted={formattedDate}
  />
{:else}
  <Datepicker
    {start}
    {end}
    bind:selected={selectedDate}
    bind:formatted={formattedDate}
  />
{/if}
<!-- <Datepicker
    style="font-size: .75rem; width: 100%;"
    bind:selected={selectedDate}
    bind:formattedSelected={formattedDate}
    bind:dateChosen
    {start}
    {end}
    highlightColor="#3b82f6"
    dayBackgroundColor="#dbeafe"
    dayTextColor="#333"
    dayHighlightedBackgroundColor="#3b82f6"
    dayHighlightedTextColor="#fff"
    format={(date) => dayjs(date).format('MM/DD/YYYY')}
  >
    <button class="custom-button">
      {#if dateChosen}
        {dayjs(formattedDate).format('MM/DD/YYYY')}
      {:else}{dayjs(new Date()).format('MM/DD/YYYY')}{/if}
    </button>
  </Datepicker> -->

<!-- </div> -->
<style>
  .text-small {
    font-size: 0.65rem;
  }
</style>
