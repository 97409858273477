<script>
  import { db } from '../../utils/services';
  import { clickOutside } from '../../utils/clickOutside';
  import { selectedOrgId, user, chartOfAccounts } from '../../utils/store';
  import { getNotificationsContext } from 'svelte-notifications';
  import Input from '../Input.svelte';
  import BaseModal from './BaseModal.svelte';

  const { addNotification } = getNotificationsContext();

  export let show = false;

  export let edit = false;
  export let id = '';
  export let accountNumber = $chartOfAccounts[0].accountNumber + 1000;
  export let oldAccountNumber = 0;
  export let name = '';
  export let description = '';

  let disabled;
  let error = '';
  $: if (
    accountNumber == 1000 ||
    accountNumber == 2000 ||
    accountNumber == 3000
  )
    disabled = true;

  $: if (accountNumber) error = '';

  function add() {
    let valid = true;

    // Find if the array contains an object by comparing the property value
    if ($chartOfAccounts.some((acc) => acc.accountNumber === accountNumber)) {
      valid = false;
      error = 'Account number already exists.';
    }

    if (accountNumber % 1000 != 0) {
      valid = false;
      error = 'Account number must be in multiples of 1000.';
    }

    if (valid && !disabled) {
      error = '';
      db.collection('accounts')
        .doc($selectedOrgId)
        .collection('accounts')
        .add({
          accountNumber: accountNumber,
          organizationId: $selectedOrgId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: $user.uid,
          lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdatedBy: $user.uid,
          description: description,
          name: name,
        })
        .then(() => {
          addNotification({
            text: 'Successfully added account!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
          });
          show = false;
        })
        .catch((error) => {
          console.log(error);
          addNotification({
            text: 'Failed to add account.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
          });
        });
    }
  }

  function update() {
    let valid = true;

    // Find if the array contains an object by comparing the property value
    if ($chartOfAccounts.some((acc) => acc.accountNumber === accountNumber)) {
      console.log(oldAccountNumber, accountNumber);
      if (oldAccountNumber !== accountNumber) {
        valid = false;
        error = 'Account number already exists.';
      }
    }

    if (accountNumber % 1000 != 0) {
      valid = false;
      error = 'Account number must be in multiples of 1000.';
    }

    if (valid && !disabled) {
      db.collection('accounts')
        .doc($selectedOrgId)
        .collection('accounts')
        .doc(id)
        .update({
          accountNumber: accountNumber,
          lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdatedBy: $user.uid,
          description: description,
          name: name,
        })
        .then(() => {
          addNotification({
            text: 'Successfully updated account!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
          });
          show = false;
        })
        .catch((error) => {
          console.log(error);
          addNotification({
            text: 'Failed to update account.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
          });
        });
    }
  }
</script>

<BaseModal bind:show>
  <!-- Heading -->
  <div class="w-full flex justify-between">
    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white">
      {#if !edit}
        New Account
      {:else}
        Edit Account
      {/if}
    </h3>
    <span
      on:click={() => (show = false)}
      class="hidden md:flex text-cfa-secondary-gray hover:bg-socius-primary hover:text-white rounded-full w-8 h-8 flex justify-center items-center"
    >
      ×
    </span>
  </div>

  <!-- Body -->
  <div class="mt-2">
    <div class="grid grid-col-12 grid-row-5 mt-4">
      <!-- Row 1 -->
      <div class="row-span-1 flex justify-between w-full">
        <div class="w-1/2 mr-1 md:mr-4">
          <Input
            label="Name"
            bind:value={name}
            placeholder="Name of account"
            bind:disabled
          />
        </div>
        <div class="w-1/2 ml-1 md:ml-4">
          <Input
            label="Description"
            bind:value={description}
            placeholder="Short description"
            bind:disabled
          />
        </div>
      </div>

      <!-- Row 2 -->
      <div class="row-span-1 flex justify-between w-full mt-2">
        <div class="w-1/2 pr-4">
          <label
            for="accountNumber"
            class="text-small text-gray-700 dark:text-gray-400"
          >
            Account Number
          </label>
          <input
            {disabled}
            bind:value={accountNumber}
            type="number"
            name="accountNumber"
            step="1000"
            max={$chartOfAccounts[0].accountNumber + 1000}
            min="4000"
            class:text-gray-400={disabled}
            class:text-gray-700={!disabled}
            class="mb-2 outline-none dark:text-gray-300 border-transparent border-b border-gray-600 w-full py-2 pr-4 placeholder-gray-300 text-xs bg-transparent"
            placeholder="Account number"
          />
          <p class="text-xs p-2 text-red-500" class:hidden={error === ''}>
            {error}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="mt-6 w-full flex items-center justify-center cursor-pointer">
    <div class="w-1/2 flex justify-end mr-4">
      {#if !edit}
        <span
          on:click={add}
          class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
        >
          Add Account
        </span>
      {:else}
        <span
          on:click={update}
          class:cursor-not-allowed={disabled}
          class:hover:bg-socius-primary={!disabled}
          class:hover:text-white={!disabled}
          class="flex items-center justify-center text-xs text-socius-alt h-8 w-28 rounded-full"
        >
          Update Account
        </span>
      {/if}
    </div>
    <div class="w-1/2 flex justify-start ml-4">
      <span
        on:click={() => (show = false)}
        class="flex items-center justify-center text-xs text-red-600 hover:text-gray-900 h-8 w-16 rounded-full hover:bg-red-100"
      >
        Close
      </span>
    </div>
  </div>
</BaseModal>

<style>
  .text-small {
    font-size: 0.65rem;
  }
</style>
