<script>
  import { darkMode } from '../../utils/store';
  import {
    Theme,
    DataTable,
    Toolbar,
    ToolbarContent,
    ToolbarSearch,
    Pagination,
    Button,
    ToolbarBatchActions,
  } from 'carbon-components-svelte';
  import TrashCan from 'carbon-icons-svelte/lib/TrashCan.svelte';
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let add = false;
  export let data;
  export let headers = [];

  $: theme = 'white';
  $: if ($darkMode) {
    theme = 'g100';
  } else {
    theme = 'white';
  }
  $: selectedRowIds = [];

  $: filteredData = data;
  let page = 1;
  let pageSize = 5;
  let pageSizes = [5, 10, 25, 50, 100];
  $: rows = data;

  function stylizing(darkMode) {
    let root = document.querySelector(':root');
    root.style.setProperty('--cds-background-brand', '#38d3ff');
    root.style.setProperty('--cds-hover-primary', '#153f59');
    root.style.setProperty('--cds-interactive-01', '#38d3ff');
    root.style.setProperty('--cds-interactive-01-hover', '#38d3ff');
    root.style.setProperty('--cds-border-subtle', 'transparent');

    if (darkMode) {
      root.style.setProperty('--cds-layer', '#1f2937');
      root.style.setProperty('--cds-layer-accent', '#111827');
      root.style.setProperty('--cds-layer-hover', '#111827');
      root.style.setProperty('--cds-field-hover', '#111827');
      root.style.setProperty('--cds-hover-ui', '#111827');
      root.style.setProperty('--cds-hover-selected-ui', '#111827');
      root.style.setProperty('--cds-ui-01', '#1f2937');
      root.style.setProperty('--cds-text-secondary', 'white');
      root.style.setProperty('--cds-text-01', 'white');
    } else {
      root.style.setProperty('--cds-layer', 'white');
      root.style.setProperty('--cds-layer-accent', '#e0e0e0');
      root.style.setProperty('--cds-layer-hover', '#d1d5db');
      root.style.setProperty('--cds-field-hover', '#d1d5db');
      root.style.setProperty('--cds-hover-ui', '#d1d5db');
      root.style.setProperty('--cds-hover-selected-ui', '#d1d5db');
      root.style.setProperty('--cds-ui-01', 'white');
      root.style.setProperty('--cds-text-secondary', 'black');
      root.style.setProperty('--cds-text-01', 'black');
    }
  }

  $: stylizing($darkMode);
</script>

<Theme bind:theme />
<div class="shadow rounded-lg">
  <DataTable
    style="border-radius: 0.5rem;"
    {headers}
    {rows}
    {pageSize}
    {page}
    sortable
    selectable
    batchSelection
    bind:selectedRowIds
    on:click:row={(ev) => dispatch('rowClick', ev.detail)}
  >
    <Toolbar>
      <ToolbarBatchActions>
        <Button
          icon={TrashCan}
          disabled={selectedRowIds.length === 0}
          on:click={() => {
            dispatch('delete', selectedRowIds);
          }}
        >
          Delete
        </Button>
      </ToolbarBatchActions>
      <ToolbarContent>
        <ToolbarSearch persistent value="" shouldFilterRows bind:filteredData />
        {#if add}
          <Button
            on:click={() => {
              dispatch('add', null);
            }}><i class="fas fa-plus-circle mr-2" />Add New</Button
          >
        {/if}
      </ToolbarContent>
    </Toolbar>
  </DataTable>

  <Pagination
    bind:pageSize
    bind:page
    totalItems={filteredData.length}
    {pageSizes}
  />
</div>
