<script>
  import { clickOutside } from '../../utils/clickOutside';
  import { bankAccountTypes } from '../../utils/store';
  import { getNotificationsContext } from 'svelte-notifications';
  import Input from '../Input.svelte';
  import Select from '../Select.svelte';
  import InputUsd from '../InputUSD.svelte';
  import {
    createBankAccount,
    updateBankAccount,
  } from '../../utils/bankAccounts';
  import BaseModal from './BaseModal.svelte';

  const { addNotification } = getNotificationsContext();

  const getOptionLabel = (option) => option.name;
  const getSelectionLabel = (option) => option.name;
  const optionIdentifier = 'type';

  export let show = false;
  export let edit = false;

  export let id = '';
  export let name = '';
  export let accountType = '';
  export let accountNumber = '0';
  export let balance = (0).toFixed(2);

  //   $: mask = accountNumber.replace(/(\d)(\d{4})/g, '*$2');
  $: mask = maskNumber();
  //   $: console.log(mask);

  //   $: accountNumberLast4 = accountNumber.replace(/(\w| )(?=(\w| ){4})/g, '');

  function maskNumber() {
    let accountMask = accountNumber;
    console.log(
      accountMask.slice(0, -4).replace(/./g, '*') + accountMask.slice(-4)
    );
  }

  let disabled;

  function add() {
    let bank = {
      name: name,
      accountType: accountType,
      accountNumber: accountNumber,
      balance: Number(balance),
    };

    createBankAccount(bank)
      .then((bankID) => {
        console.log(bankID);
        addNotification({
          text: 'Successfully created bank!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        show = false;
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to add bank.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  function update() {
    let bank = {
      name: name,
      accountType: accountType,
      accountNumber: accountNumber,
      balance: Number(balance),
      id: id,
    };

    updateBankAccount(bank)
      .then((bankID) => {
        console.log(bankID);
        addNotification({
          text: 'Successfully created bank!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        show = false;
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to add bank.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }
</script>

<BaseModal bind:show>
  <!-- Heading -->
  <div class="w-full flex justify-between">
    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white">
      {#if !edit}
        New Bank Account
      {:else}
        Edit Bank Account
      {/if}
    </h3>
    <span
      on:click={() => (show = false)}
      class="hidden md:flex text-cfa-secondary-gray hover:bg-socius-primary hover:text-white rounded-full w-8 h-8 flex justify-center items-center"
    >
      ×
    </span>
  </div>

  <!-- Body -->
  <div class="mt-2">
    <div class="grid grid-col-12 mt-4">
      <!-- Row 1 -->
      <div class="md:flex md:justify-between w-full">
        <div class="w-full md:w-1/2 mr-1 md:mr-4">
          <Input
            label="Name"
            bind:value={name}
            placeholder="Name of account"
            bind:disabled
          />
        </div>
        <div class="w-full md:w-1/2 ml-1 md:ml-4">
          <Select
            label="Type"
            items={$bankAccountTypes}
            {getOptionLabel}
            {getSelectionLabel}
            {optionIdentifier}
            placeholder="Select account type"
            bind:selectedValue={accountType}
          />
        </div>
      </div>

      <!-- Row 2 -->
      <div class="md:flex md:justify-between w-full mt-4">
        <div class="w-full md:w-1/2 mr-1 md:mr-4">
          <Input
            label="Account Number"
            bind:value={accountNumber}
            placeholder="Number of account"
            bind:disabled
          />
        </div>
        <div class="w-full md:w-1/2 ml-1 md:ml-4">
          <InputUsd label="Balance" bind:value={balance} placeholder="0.00" />
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="mt-6 w-full flex items-center justify-center cursor-pointer">
    <div class="w-1/2 flex justify-end mr-4">
      {#if !edit}
        <span
          on:click={add}
          class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
        >
          Add Account
        </span>
      {:else}
        <span
          on:click={update}
          class:cursor-not-allowed={disabled}
          class:hover:bg-socius-primary={!disabled}
          class:hover:text-white={!disabled}
          class="flex items-center justify-center text-xs text-socius-alt h-8 w-28 rounded-full"
        >
          Update Account
        </span>
      {/if}
    </div>
    <div class="w-1/2 flex justify-start ml-4">
      <span
        on:click={() => (show = false)}
        class="flex items-center justify-center text-xs text-red-600 hover:text-gray-900 h-8 w-16 rounded-full hover:bg-red-100"
      >
        Close
      </span>
    </div>
  </div>
</BaseModal>
