import { receiptsRef } from './services';

export async function urlToFile(url, name) {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], name, { type: blob.type });
    return file;
}

export async function uploadFiles(transactionId, prevFiles, files) {
    let deletedFiles = prevFiles.filter((x) => !files.accepted.includes(x));

    for (let i = 0; i < deletedFiles.length; i++) {
        let fileRef = receiptsRef.child(
            `${$selectedOrgId}/${transactionId}/${deletedFiles[i].name}`
        );

        // Delete the file
        fileRef
            .delete()
            .then(() => {
                //   console.log('successfully deleted file ', deletedFiles[i].name);
            })
            .catch((error) => {
                console.log(`failed to delete ${deletedFiles[i].name}: ${error}`);
            });
    }

    for (let i = 0; i < files.accepted.length; i++) {
        let fileRef = receiptsRef.child(
            `${$selectedOrgId}/${transactionId}/${files.accepted[i].name}`
        );
        fileRef
            .put(files.accepted[i])
            .then((snapshot) => {
                //   console.log('Successfully uploaded file!');
            })
            .catch((err) => {
                console.log(`failed to upload ${files.accepted[i].name}: ${error}`);
            });
    }
}

// function listAllImages() {
//     loadingReceipts = true;
//     // console.log('listing images');
//     let numImages = 0;
//     let imagesRef = receiptsRef.child(`${$selectedOrgId}/${transactionId}`);
//     imagesRef
//         .listAll()
//         .then((res) => {
//             if (res.items.length === 0) {
//                 loadingReceipts = false;
//                 editReceipts = false;
//             }
//             res.items.forEach((itemRef, index) => {
//                 //   console.log(`Retrieving image (${index}) ${itemRef.name}`);
//                 itemRef
//                     .getDownloadURL()
//                     .then((url) => {
//                         urlToFile(url, itemRef.name).then((file) => {
//                             files.accepted.push(file);
//                             prevFiles.push(file);
//                             numImages = numImages + 1;
//                             if (numImages === res.items.length) {
//                                 //   console.log('finished loading');
//                                 loadingReceipts = false;
//                                 editReceipts = false;
//                             }
//                         });
//                     })
//                     .catch(function (error) {
//                         console.log('error downloading file: ', error);
//                     });
//             });
//         })
//         .catch((error) => {
//             // Uh-oh, an error occurred!
//             console.log('error listing images', error);
//         });
// }