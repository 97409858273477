import "firebase/firestore";

let db = firebase.firestore();
var storageRef = firebase.storage().ref();

// Create a child reference
var receiptsRef = storageRef.child('receipts');
var archiveRef = storageRef.child('archive');

// const appCheck = firebase.appCheck();
// appCheck.activate(
//     '6LcOSJccAAAAABszvp2uTCKrueIi8w2V-TsfQAWi', // reCAPTCHA v3 site key (public key).
//     true); // automatically refreshes App Check tokens as needed

export { db, storageRef, receiptsRef, archiveRef };
