<script>
  import { createEventDispatcher } from 'svelte';
  import Input from './Input.svelte';
  import Select from './Select.svelte';
  import DatePicker from './DatePicker.svelte';
  import { selectedOrgId, idToken, chartOfAccounts } from '../utils/store';
  import { getNotificationsContext } from 'svelte-notifications';

  const { addNotification } = getNotificationsContext();
  const dispatch = createEventDispatcher();

  const TRANSACTION_TYPE = 'Transaction';
  const INVOICE_TYPE = 'Invoice';

  let selectedAccounts = null;
  const types = [TRANSACTION_TYPE, INVOICE_TYPE];
  let selectedType = '';
  let name = '';
  const today = new Date();
  let startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  let formattedStartDate = startDate.toLocaleDateString('en-US');
  let endDate = new Date();
  let formattedEndDate = endDate.toLocaleDateString('en-US');

  const getOptionLabel = (option) => option.name;
  const getSelectionLabel = (option) => option.name;
  const optionIdentifier = 'id';
  function chooseAll() {
    selectedAccounts = $chartOfAccounts;
  }

  async function exportData() {
    let headers = [];
    if (selectedAccounts == null || selectedAccounts == undefined) {
      chooseAll();
    }

    const res = await fetch(`/export-data`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${$idToken.replaceAll(/"/g, '')}`,
      },
      credentials: 'include',
      body: JSON.stringify({
        selectedOrgID: $selectedOrgId,
        type: selectedType.value,
        name: name,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        accounts: selectedAccounts,
      }),
    });

    const data = await res.json();
    if (res.ok) {
      console.log('sucessfully exported data ');
      addNotification({
        text: 'Successfully exported data!',
        position: 'bottom-center',
        type: 'success',
        removeAfter: 2000,
      });
      dispatch('notify', true);
      return true;
    } else {
      console.log('failed to export data');
      addNotification({
        text: 'Failed to export data.',
        position: 'bottom-center',
        type: 'error',
        removeAfter: 2000,
      });
      return false;
    }
  }
</script>

<div class="col-span-12 lg:col-span-8">
  <div
    class="shadow-lg rounded bg-white dark:bg-gray-800 dark:text-gray-300 px-6 pt-6 pb-8 mb-8 overflow-visible"
  >
    <div class="flex justify-between items-center">
      <p class="text-xl font-bold mb-4 dark:text-white">Export Data</p>
    </div>

    <div class="w-full md:flex-row md:flex">
      <div class="w-full md:w-1/2 pr-4">
        <Select
          label="Type"
          items={types}
          placeholder="Type"
          bind:selectedValue={selectedType}
        />
      </div>

      <div class="w-full md:w-1/2 md:pl-4 mt-4 md:mt-0">
        <Input
          label="Name of Export File"
          bind:value={name}
          placeholder="Name of file"
        />
      </div>
    </div>

    <div
      class="mt-6 md:mt-4 md:flex-row md:flex justify-between w-full h-36 md:h-16"
    >
      <div class="w-full md:w-1/2 md:mr-4 relative h-16">
        <DatePicker
          label="Start Date"
          bind:selectedDate={startDate}
          bind:end={endDate}
          bind:formattedDate={formattedStartDate}
        />
      </div>
      <div class="w-full md:w-1/2 md:ml-4 relative h-16 mt-4 md:mt-0">
        <DatePicker
          label="End Date"
          bind:selectedDate={endDate}
          bind:start={startDate}
          bind:formattedDate={formattedEndDate}
          end={today}
        />
      </div>
    </div>

    {#if $chartOfAccounts && $chartOfAccounts.length > 0 && selectedType.value == TRANSACTION_TYPE}
      <div class="mt-6 md:mt-4 md:flex-row md:flex justify-between w-full">
        <div class="w-full md:w-4/5 relative">
          <Select
            label="Accounts"
            items={$chartOfAccounts}
            placeholder="Filtering by all accounts"
            bind:selectedValue={selectedAccounts}
            {getOptionLabel}
            {getSelectionLabel}
            {optionIdentifier}
            isMulti="true"
            isClearable="true"
            listPlacement="top"
          />
        </div>
        <div
          class="w-full md:w-1/5 md:ml-4 relative mt-4 md:mt-0 flex items-center justify-center"
        >
          <button
            class="rounded uppercase px-4 py-2 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
            on:click={chooseAll}
          >
            All
          </button>
        </div>
      </div>
    {/if}

    {#if selectedType.value == TRANSACTION_TYPE || selectedType.value == INVOICE_TYPE}
      <div class="mt-8 flex justify-center">
        <button
          class="w-48 rounded uppercase px-8 py-4 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
          on:click={exportData}
        >
          Export
        </button>
      </div>
    {/if}
  </div>
</div>
