import { get } from 'svelte/store';

export async function createCustomer(customer) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/customers/${selectedOrgId}/create`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(customer),
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully created customer ', data);
        return data["customerID"];
      } else {
        console.log('failed to create customer');
        throw new Error("failed to create customer")
      }
}

export async function updateCustomer(customer) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/customers/${selectedOrgId}/update/${customer.id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(customer),
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully updated customer ', data);
        return data["customerID"];
      } else {
        console.log('failed to updated customer');
        throw new Error("failed to updated customer")
      }
}

export async function deleteCustomer(customerID) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/customers/${selectedOrgId}/delete/${customerID}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully deleted customer ', data);
        return data["customerID"];
      } else {
        console.log('failed to delete customer');
        throw new Error("failed to delete customer")
      }
}
