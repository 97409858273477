<script>
  import { onMount } from 'svelte';
  import Heading from '../../components/Heading.svelte';
  import ExportData from '../../components/ExportData.svelte';
  import { Shadow } from 'svelte-loading-spinners';
  import { saveAs } from 'file-saver';
  import { selectedOrgId } from '../../utils/store';
  import { archiveRef } from '../../utils/services';
  import { getNotificationsContext } from 'svelte-notifications';
  import FileArchive from '../../components/FileArchive.svelte';
  import DeleteModal from '../../components/modals/DeleteModal.svelte';

  const { addNotification } = getNotificationsContext();

  let rowDelete = false;
  let currentIndex;
  let loadingReceipts = false;
  let prevFiles = [];
  let files = {
    accepted: [],
    rejected: [],
  };

  function resetFiles() {
    files = {
      accepted: [],
      rejected: [],
    };
  }

  function handleRemoveFile() {
    const file = files.accepted[currentIndex];
    archiveRef
      .child(`${$selectedOrgId}/${file.name}`)
      .delete()
      .then(() => {
        files.accepted.splice(currentIndex, 1);
        files.accepted = [...files.accepted];
        rowDelete = false;
        addNotification({
          text: 'Successfully deleted file.',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to delete file.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }
  //   function handleRemoveAll() {
  //     archiveRef
  //       .child(`${$selectedOrgId}`)
  //       .delete()
  //       .then(() => {
  //         files.accepted = [];
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         addNotification({
  //           text: 'Failed to delete file.',
  //           position: 'bottom-center',
  //           type: 'error',
  //           removeAfter: 2000,
  //         });
  //       });
  //   }

  function download(index) {
    const file = files.accepted[index];
    saveAs(file);
  }

  async function urlToFile(url, name) {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], name, { type: blob.type });
    return file;
  }

  function listAllImages() {
    resetFiles();
    loadingReceipts = true;
    // console.log('listing images');
    let numImages = 0;
    let imagesRef = archiveRef.child(`${$selectedOrgId}`);
    imagesRef
      .listAll()
      .then((res) => {
        if (res.items.length === 0) {
          loadingReceipts = false;
          editReceipts = false;
        }
        res.items.forEach((itemRef, index) => {
          //   console.log(`Retrieving image (${index}) ${itemRef.name}`);
          itemRef
            .getDownloadURL()
            .then((url) => {
              urlToFile(url, itemRef.name).then((file) => {
                files.accepted.push(file);
                prevFiles.push(file);
                numImages = numImages + 1;
                if (numImages === res.items.length) {
                  //   console.log('finished loading');
                  sortFiles();
                  loadingReceipts = false;
                }
              });
            })
            .catch(function (error) {
              console.log('error downloading file: ', error);
            });
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log('error listing images', error);
      });
  }

  onMount(() => {
    listAllImages();
  });

  function refresh() {
    // console.log('refreshing!');
    window.location.reload();
  }

  function deleteItem(index) {
    currentIndex = index;
    rowDelete = true;
  }

  function sortFiles() {
    files.accepted.sort(function (a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }
</script>

<Heading heading="Archive" />

<ExportData on:notify={refresh} />

{#if rowDelete}
  <DeleteModal bind:show={rowDelete} on:notify={handleRemoveFile} />
{/if}

<div
  class="shadow-lg rounded bg-white dark:bg-gray-800 dark:text-gray-300 p-6 overflow-visible"
>
  <div class="flex justify-between items-center">
    <p class="text-xl font-bold mb-2 dark:text-white">Archived Files</p>
  </div>
  {#if loadingReceipts}
    <div class="my-4 w-full h-full flex justify-center items-center">
      <Shadow size="25" unit="px" duration="1s" color="#38d3ff" />
    </div>
  {:else}
    <!-- <div class="text-xs mt-4 dark:text-gray-300"> -->
    <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
      {#if files.accepted.length > 0}
        {#each files.accepted as item, index}
          <FileArchive
            itemName={item.name}
            itemIndex={index}
            on:remove={(ev) => deleteItem(ev.detail.index)}
            on:download={(ev) => download(ev.detail.index)}
          />
        {/each}
      {:else}
        <div class="flex justify-center text-xl text-red-500">
          No files found.
        </div>
      {/if}
    </div>
  {/if}
</div>
