<script lang="ts">
  import Checkbox from '../Checkbox.svelte';
  import { clickOutside } from '../../utils/clickOutside';
  import Input from '../Input.svelte';
  import {
    customers,
    invoices,
    transactions,
    bankAccounts,
  } from '../../utils/store';
  import { createEventDispatcher, onMount } from 'svelte';
  import { getNotificationsContext } from 'svelte-notifications';
  import InputUsd from '../InputUSD.svelte';
  import InputPercentage from '../InputPercentage.svelte';
  import DatePicker from '../DatePicker.svelte';
  import Select from '../Select.svelte';
  import { createInvoice, updateInvoice } from '../../utils/invoices';
  import BaseModal from './BaseModal.svelte';

  const { addNotification } = getNotificationsContext();

  const dispatch = createEventDispatcher();

  export let show = false;
  const getOptionLabel = (option) => option.name;
  const getSelectionLabel = (option) => option.name;
  const optionIdentifier = 'id';

  let methods = ['Cash', 'ETF', 'Check'];
  let categories = ['Sales', 'Services', 'Other Income'];
  let selectedCustomer = '';

  $: preTaxTotal = (0).toFixed(2);
  $: salesTaxTotal = (0).toFixed(2);
  $: totalAmount = (
    Math.round((Number(preTaxTotal) + Number(salesTaxTotal)) * 100) / 100
  ).toFixed(2);

  if ($customers.length > 0) {
    selectedCustomer = $customers[0];
  }

  export let edit = false;
  export let id = '';
  export let transactionId = '';
  export let transactionNumber = 0;
  export let invoiceNumber = 0;
  export let paid = false;
  export let bankAccount = {};
  export let customer = '';
  export let taxRate = 6;
  export let terms = '';
  export let amountTotal = (0).toFixed(2);
  export let description = '';
  export let selectedDate = new Date(); // date user chose, defaults to today
  export let selectedCategory = {
    value: categories[0],
    label: categories[0],
  };
  export let selectedMethod = {
    value: methods[0],
    label: methods[0],
  };
  export let lineItems = [{ description: '', quantity: 0, unitPrice: 0.0 }];
  let oldAmount = amountTotal;

  onMount(() => {
    if (customer != '') {
      selectedCustomer = customer;
    }
    totalAmount = amountTotal;
  });

  function getInvoiceNumber() {
    let numbers = [];
    numbers = $invoices.sort(function (a, b) {
      return a.invoiceNumber < b.invoiceNumber ? 1 : -1;
    });

    if (numbers.length == 0) {
      return 1;
    }

    return numbers[0].invoiceNumber + 1;
  }

  function getTransactionNumber() {
    let numbers = [];
    numbers = $transactions.sort(function (a, b) {
      return a.transactionNumber < b.transactionNumber ? 1 : -1;
    });

    if (numbers.length == 0) {
      return 1;
    }

    return numbers[0].transactionNumber + 1;
  }

  function add() {
    invoiceNumber = getInvoiceNumber();

    let invoice = {
      category: selectedCategory.value,
      customer: {
        id: selectedCustomer.id,
      },
      description: description,
      dueDate: selectedDate,
      invoiceNumber: invoiceNumber,
      lineItems: lineItems,
      method: selectedMethod.value,
      paid: paid,
      taxRate: taxRate,
      terms: terms,
      transactionId: transactionId,
      transactionNumber: transactionNumber,
    };

    if (paid && transactionId == '') {
      invoice['transactionNumber'] = getTransactionNumber();
    }

    if (bankAccount && Object.keys(bankAccount).length !== 0) {
      invoice['bankAccount'] = {
        id: bankAccount.id,
      };
    }

    console.log(invoice);

    createInvoice(invoice)
      .then((invoiceID) => {
        console.log(invoiceID);
        addNotification({
          text: 'Successfully created invoice!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        show = false;
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to add invoice.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  function update() {
    let invoice = {
      customer: {
        id: selectedCustomer.id,
      },
      description: description,
      dueDate: selectedDate,
      method: selectedMethod.value,
      category: selectedCategory.value,
      invoiceNumber: invoiceNumber,
      id: id,
      paid: paid,
      terms: terms,
      lineItems: lineItems,
      taxRate: taxRate,
      transactionNumber: transactionNumber,
    };

    if (bankAccount && Object.keys(bankAccount).length !== 0) {
      invoice['bankAccount'] = {
        id: bankAccount.id,
      };
    }

    if (paid && transactionId == '') {
      invoice['transactionNumber'] = getTransactionNumber();
    }

    console.log(invoice);

    updateInvoice(invoice)
      .then((invoiceID) => {
        console.log(invoiceID);
        addNotification({
          text: 'Successfully updated invoice!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        show = false;
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to update invoice.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  let no = false;
  function addCustomer() {
    dispatch('notify', { newCustomer: true });
  }

  function addItem() {
    lineItems = [
      ...lineItems,
      { description: '', quantity: 0, unitPrice: 0.0 },
    ];
  }
  function removeItem() {
    lineItems.pop();
    lineItems = lineItems; //so svelte re-renders the list
  }
</script>

<BaseModal bind:show>
  <!-- Heading -->
  <div class="w-full flex justify-between">
    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white">
      {#if !edit}
        New Invoice
      {:else}
        Edit Invoice
      {/if}
    </h3>
    <span
      on:click={() => (show = false)}
      class="hidden md:flex text-cfa-secondary-gray hover:bg-socius-primary hover:text-white rounded-full w-8 h-8 flex justify-center items-center"
    >
      ×
    </span>
  </div>
  <div class="mt-2">
    <div class="grid grid-col-12 grid-row-5 mt-4">
      {#if $customers.length == 0 && !no}
        <div
          class="row-span-1 flex items-center justify-between w-full text-xs border border-red-500 p-2 mb-2"
        >
          <span class="text-red-500"
            >There are no customers. Would you like to add a new one?</span
          >
          <div class="text-sm">
            <i
              class="fas fa-plus-circle text-green-500 px-2"
              on:click={addCustomer}
            />
            <i
              class="fas fa-trash-alt text-red-500"
              on:click={() => (no = true)}
            />
          </div>
        </div>
      {/if}
      <!-- Row 1 -->
      <div class="row-span-1 flex justify-between w-full">
        <div class="w-1/2 mr-1 md:mr-4">
          <Select
            label="Customer"
            items={$customers}
            {getOptionLabel}
            {getSelectionLabel}
            {optionIdentifier}
            placeholder="Select customer"
            bind:selectedValue={selectedCustomer}
          />
        </div>
        <div class="w-1/2 ml-1 md:ml-4">
          <Input
            label="Description/PO#"
            bind:value={description}
            placeholder="Short description"
          />
        </div>
      </div>

      <!-- Row 4 -->
      <div class="row-span-1 flex justify-between w-full mt-4">
        <div class="w-1/2 mr-1 md:mr-4">
          <Select
            label="Method"
            items={methods}
            placeholder="Select method type"
            bind:selectedValue={selectedMethod}
          />
        </div>
        <div class="w-1/2 ml-1 md:ml-4">
          <Select
            label="Category"
            items={categories}
            placeholder="Select category"
            bind:selectedValue={selectedCategory}
          />
        </div>
      </div>

      <!-- Row 3 -->
      <div class="row-span-1 flex justify-between w-full mt-4 text-xs">
        <div class="w-1/2 mr-1 md:mr-4 relative">
          <DatePicker label="Due Date" bind:selectedDate />
        </div>
        <div class="w-1/2 ml-1 md:ml-4">
          <InputPercentage
            label="Tax Rate"
            bind:value={taxRate}
            placeholder="6"
          />
        </div>
      </div>

      <!-- Row 3 -->
      <div class="row-span-1 flex justify-between w-full mt-4 text-xs">
        <div class="w-full">
          <label
            for="description"
            class="text-xs text-gray-600 dark:text-gray-400"
          >
            Terms & Instructions
          </label>
          <textarea
            type="text"
            bind:value={terms}
            name="terms"
            class="mb-2 outline-none border-transparent border-b border-gray-600 w-full py-2 pr-4 dark:text-gray-300 placeholder-gray-300 dark:placeholder-gray-500 text-xs bg-transparent"
            placeholder="Insert any terms and instructions to customer"
          />
        </div>
      </div>

      <!-- Row 3 -->
      <div class="row-span-1 flex justify-between w-full mt-8 mb-4 text-xs">
        <div class="w-full md:w-1/2 md:mr-4 mt-4 md:mt-0">
          <span class="text-gray-600 dark:text-gray-400">
            Payment
            <span
              class="small-text relative bottom-1 inline text-gray-500 dark:text-gray-300"
              >*marking an invoice as paid will create a transaction.</span
            >
          </span>
          <div
            class="flex justify-center items-center md:justify-start md:items-start"
          >
            <Checkbox
              label="Has this invoice been paid?"
              checked={paid}
              on:notify={(event) => (paid = event.detail.checked)}
            />
          </div>
        </div>
        {#if paid}
          <div class="w-full md:w-1/2 md:ml-4 mt-4 md:mt-0">
            <Select
              label="Bank Account"
              items={$bankAccounts}
              {getOptionLabel}
              {getSelectionLabel}
              {optionIdentifier}
              placeholder="Select bank account for transaction"
              bind:selectedValue={bankAccount}
            />
          </div>
        {/if}
      </div>

      <!-- Row 4 -->
      <div class="row-span-1 flex justify-between w-full mt-4">
        <div
          class="w-full border-b mb-1 text-sm text-gray-700 dark:text-gray-300 flex justify-between pb-1"
        >
          <span>Line Items</span>
          <div class="text-sm mr-2">
            <i
              class="fas fa-plus-circle text-green-500 px-2"
              on:click={addItem}
            />
            <i class="fas fa-minus-circle text-red-500" on:click={removeItem} />
          </div>
        </div>
      </div>

      <!-- Row 4 -->
      {#each lineItems as item}
        <div class="row-span-1 flex justify-between w-full text-xs mt-2">
          <div class="w-full md:w-1/3 mr-1 md:mr-4">
            <Input
              label="Item Description"
              bind:value={item.description}
              placeholder="Short description"
            />
          </div>
          <div class="w-full md:w-1/3 mx-1 md:mx-4">
            <label
              for="qty"
              class="small-text text-gray-600 dark:text-gray-400"
            >
              Quantity
            </label>
            <input
              type="number"
              bind:value={item.quantity}
              name="qty"
              min="0"
              class="mb-2 outline-none dark:text-gray-300 border-transparent border-b border-gray-600 w-full py-2 pr-4 placeholder-gray-300 text-xs bg-transparent"
              placeholder="0"
            />
          </div>
          <div class="w-full md:w-1/3 ml-1 md:ml-4">
            <InputUsd
              label="Unit Price"
              bind:value={item.unitPrice}
              placeholder="0.00"
            />
          </div>
        </div>
      {/each}
    </div>
  </div>

  <!-- Footer -->
  <div class="mt-6 w-full flex items-center justify-center cursor-pointer">
    <div class="w-1/2 flex justify-end mr-4">
      {#if !edit}
        <span
          on:click={add}
          class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
        >
          Add Invoice
        </span>
      {:else}
        <span
          on:click={update}
          class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
        >
          Update Invoice
        </span>
      {/if}
    </div>
    <div class="w-1/2 flex justify-start ml-4">
      <span
        on:click={() => (show = false)}
        class="flex items-center justify-center text-xs text-red-600 hover:text-gray-900 h-8 w-16 rounded-full hover:bg-red-100"
      >
        Close
      </span>
    </div>
  </div>
</BaseModal>

<style>
  .small-text {
    font-size: 0.5rem;
  }
</style>
