import { get } from 'svelte/store';

export async function createInvoice(invoice) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/invoices/${selectedOrgId}/create`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(invoice),
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully created invoice ', data);
        return data["invoiceID"];
      } else {
        console.log('failed to create invoice');
        throw new Error("failed to create invoice")
      }
}

export async function updateInvoice(invoice) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/invoices/${selectedOrgId}/update/${invoice.id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(invoice),
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully updated invoice ', data);
        return data["invoiceID"];
      } else {
        console.log('failed to updated invoice');
        throw new Error("failed to updated invoice")
      }
}

export async function deleteInvoice(invoiceID) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/invoices/${selectedOrgId}/delete/${invoiceID}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully deleted invoice ', data);
        return data["invoiceID"];
      } else {
        console.log('failed to delete invoice');
        throw new Error("failed to delete invoice")
      }
}
