<script>
  import { chartOfAccounts, selectedOrgId, darkMode } from '../../utils/store';
  import { db } from '../../utils/services';
  import { Shadow } from 'svelte-loading-spinners';
  import AccountModal from '../../components/modals/AccountModal.svelte';
  import DeleteModal from '../../components/modals/DeleteModal.svelte';
  import { redirect } from '@sveltech/routify';
  import { getNotificationsContext } from 'svelte-notifications';
  import Heading from '../../components/Heading.svelte';
  import CarbonTable from '../../components/table/CarbonTable.svelte';

  const { addNotification } = getNotificationsContext();

  let title = 'Chart of Accounts';

  //   Used for when clicking on a row to pop up modal
  let modal = false;
  let loading = false;
  let rowDelete = false;
  let currentEntry = {};
  let edit = false;
  let id = '';
  let accountNumber = 0;
  let name = '';
  let description = '';

  let headers = [
    { key: 'accountNumber', value: 'Account #' },
    { key: 'name', value: 'Name' },
    { key: 'description', value: 'Description' },
  ];

  $: theme = 'white';
  $: if ($darkMode) {
    theme = 'g100';
  } else {
    theme = 'white';
  }
  $: selectedRowIds = [];

  if (
    !$selectedOrgId ||
    $selectedOrgId == 'undefined' ||
    $selectedOrgId == 'null'
  ) {
    $redirect('/settings');
  }

  let disabled = false;
  $: if (
    currentEntry.accountNumber == 1000 ||
    currentEntry.accountNumber == 2000 ||
    currentEntry.accountNumber == 3000
  ) {
    disabled = true;
  } else {
    disabled = false;
  }

  function checkDisabled(accNum) {
    if (accNum == 1000 || accNum == 2000 || accNum == 3000) {
      return true;
    } else {
      return false;
    }
  }

  function deleteChartAccount(accID) {
    db.collection('accounts')
      .doc($selectedOrgId)
      .collection('accounts')
      .doc(accID)
      .delete()
      .then(() => {
        addNotification({
          text: 'Successfully deleted account!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        rowDelete = false;
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to delete account.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  function deleteRowConfirmation(event) {
    console.log(selectedRowIds);
    for (var i = 0; i < selectedRowIds.length; i++) {
      let accID = selectedRowIds[i];
      if (checkDisabled(accID)) {
        rowDelete = false;
        addNotification({
          text: "Can't delete Vendors, Customers, or Utilities.",
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
        selectedRowIds = [];
        return;
      } else {
        deleteChartAccount(accID);
      }
    }
    selectedRowIds = [];
    rowDelete = false;
  }

  function addNew(event) {
    console.log('adding');
    edit = false;
    id = '';
    accountNumber = 0;
    name = '';
    description = '';
    modal = true;
  }

  function rowClick(event) {
    edit = true;
    id = event.detail.id;
    accountNumber = event.detail.accountNumber;
    name = event.detail.name;
    description = event.detail.description;
    modal = true;
  }

  $: if (!$chartOfAccounts) {
    loading = true;
  } else {
    loading = false;
  }
</script>

{#if modal}
  <AccountModal
    bind:show={modal}
    {edit}
    {id}
    {accountNumber}
    oldAccountNumber={accountNumber}
    {name}
    {description}
  />
{/if}
{#if rowDelete}
  <DeleteModal
    bind:show={rowDelete}
    bind:disabled
    on:notify={deleteRowConfirmation}
  />
{/if}

{#if !loading}
  <Heading heading={title} />
{/if}

{#if loading}
  <div class="w-full h-full flex justify-center items-center">
    <Shadow size="60" unit="px" duration="1s" color="#3b82f6" />
  </div>
{:else if !$chartOfAccounts}
  <div class="flex items-center justify-center">
    <div class="w-96 text-center grid text-sm text-gray-600">
      <span class="text-red-600 text-lg">Error</span>
      <span
        >Failed to load accounts. Please reach out to support@sociusco.com for
        help.</span
      >
    </div>
  </div>
{:else}
  <CarbonTable
    {headers}
    add="true"
    bind:data={$chartOfAccounts}
    on:add={addNew}
    on:rowClick={rowClick}
    on:delete={(ev) => {
      selectedRowIds = ev.detail;
      rowDelete = true;
    }}
  />
{/if}
