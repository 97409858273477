import { get } from 'svelte/store';

export async function createBankAccount(bankAccount) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/bank-accounts/${selectedOrgId}/create`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(bankAccount),
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully created bankAccount ', data);
        return data["bankAccountID"];
      } else {
        console.log('failed to create bankAccount');
        throw new Error("failed to create bankAccount")
      }
}

export async function updateBankAccount(bankAccount) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/bank-accounts/${selectedOrgId}/update/${bankAccount.id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(bankAccount),
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully updated bankAccount ', data);
        return data["bankAccountID"];
      } else {
        console.log('failed to updated bankAccount');
        throw new Error("failed to updated bankAccount")
      }
}

export async function deleteBankAccount(bankAccountID) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/bank-accounts/${selectedOrgId}/delete/${bankAccountID}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully deleted bankAccount ', data);
        return data["bankAccountID"];
      } else {
        console.log('failed to delete bankAccount');
        throw new Error("failed to delete bankAccount")
      }
}
