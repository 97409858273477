import { get } from 'svelte/store';

export async function createTransaction(transaction) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/transactions/${selectedOrgId}/create`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(transaction),
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully created transaction ', data);
        return data["transactionID"];
      } else {
        console.log('failed to create transaction');
        throw new Error("failed to create transaction")
      }
}

export async function updateTransaction(transaction) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/transactions/${selectedOrgId}/update/${transaction.id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(transaction),
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully updated transaction ', data);
        return data["transactionID"];
      } else {
        console.log('failed to updated transaction');
        throw new Error("failed to updated transaction")
      }
}

export async function deleteTransaction(transactionID) {
    const idToken = localStorage.getItem("idToken").replaceAll(/"/g, '')
    const selectedOrgId = localStorage.getItem("selectedOrgId")

    const res = await fetch(`/api/business-buddy/transactions/${selectedOrgId}/delete/${transactionID}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'content-type': 'application/json'
        },
        credentials: 'include',
      });
  
      if (res.ok) {
        const data = await res.json();
        // console.log('sucessfully deleted transaction ', data);
        return data["transactionID"];
      } else {
        console.log('failed to delete transaction');
        throw new Error("failed to delete transaction")
      }
}
