<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let itemName = '';
  export let itemIndex = -1;
</script>

<div
  class="rounded mb-1 px-4 py-4 dark:text-gray-300 bg-white dark:bg-gray-700 text-sm shadow"
>
  <i
    class="text-red-500 fas fa-trash cursor-pointer"
    on:click={() => dispatch('remove', { index: itemIndex })}
  />
  <i
    on:click={() => dispatch('download', { index: itemIndex })}
    class="ml-4 text-socius-alt fas fa-download cursor-pointer"
  />
  <span class="ml-4">{itemName}</span>
</div>
